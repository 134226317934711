<template>
  <div class="mortgage-other-method-table">
    <!-- ============================= Filter ============================ -->
    <filter-panel
      v-model="isFilterActive"
      @clear="clearFilter"
      @filter="filterData"
    >
      <div class="row p-2">
        <fd-input
          v-model="filter['appUser:name[partial]']"
          class="col-12 sm-col-6 md-col-4 px-1 mb-2"
          label="Buyer Name"
          type="text"
          @keyup.enter.native="filterData"
        >
        </fd-input>
      </div>
    </filter-panel>
    <!-- ========================= Table Listing ========================= -->
    <div class="card">
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="mortgageOtherTableColumns"
        :rows="mortgageOtherTableData"
        :totalRows="mortgageOtherTablePagination.total"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        @on-row-click="toLeadDetail"
      >
        <div slot="emptystate">
          <no-data
            message='There is no mortgage using "Other" method at the moment.'
          ></no-data>
        </div>
        <div slot="table-actions" class="p-1">
          <!-- <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button> -->
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- Table Buttons -->
          <span v-if="props.column.field == 'actions'" @click.stop> </span>
          <!-- ======================== AGENT INFO ======================= -->
          <span
            v-else-if="
              props.column.field == 'assignedAgent' &&
              !_.isEmpty(
                props.row.projectPurchaseMortgage.projectPurchase.agent
              )
            "
          >
            <p>
              {{ props.row.projectPurchaseMortgage.projectPurchase.agent.name }}
            </p>
            <span class="d-flex align-items-center">
              <i class="fas fa-phone mr-1"></i>
              {{
                $getUserPhoneNumber(
                  props.row.projectPurchaseMortgage.projectPurchase.agent.user
                )
              }}
            </span>
            <span class="d-flex align-items-center">
              <i class="fas fa-envelope mr-1"></i
              >{{
                props.row.projectPurchaseMortgage.projectPurchase.agent.user
                  .email
              }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  components: {},
  mixins: [vueGoodTable],
  props: {
    detailRouteName: {
      type: String
    },
    getMortgageOthers: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      mortgageOtherTableData: [],
      mortgageOtherTablePagination: {},
      mortgageOtherTableColumns: [
        {
          label: "Mortgage Method",
          field: "mortgageCategory"
        },
        {
          label: "Buyer Name",
          field:
            "projectPurchaseMortgage.projectPurchase.projectPurchaseReservation.purchaserName1"
        },
        {
          label: "Buyer Contact No.",
          field:
            "projectPurchaseMortgage.projectPurchase.projectPurchaseReservation.phoneContact"
        },
        {
          label: "Buyer Email",
          field:
            "projectPurchaseMortgage.projectPurchase.projectPurchaseReservation.email"
        },
        {
          label: "Agent",
          field: "assignedAgent"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "appUser:name[partial]": ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getAllMortgageOthers();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ page: params.currentPage });
      this.getAllMortgageOthers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ perPage: params.currentPerPage });
      this.getAllMortgageOthers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toLeadDetail(event) {
      let id = event.row.id;
      this.$router.push({
        name: this.detailRouteName,
        params: {
          id: id
        }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.$store.commit("setIsLoading", true);

      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getAllMortgageOthers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    clearFilter() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllMortgageOthers().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    // ========================== API Related =========================
    async getAllMortgageOthers() {
      try {
        let filteredParam = this.$cleanQueryParam(this.filter);

        let data = await this.getMortgageOthers({
          queries: {
            ...filteredParam
          },
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        });

        this.mortgageOtherTableData = this._.cloneDeep(data.data);
        this.mortgageOtherTablePagination = this._.cloneDeep(
          data.meta.pagination
        );
        return;
      } catch (error) {
        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
