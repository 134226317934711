var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mortgage-other-method-table"},[_c('filter-panel',{on:{"clear":_vm.clearFilter,"filter":_vm.filterData},model:{value:(_vm.isFilterActive),callback:function ($$v) {_vm.isFilterActive=$$v},expression:"isFilterActive"}},[_c('div',{staticClass:"row p-2"},[_c('fd-input',{staticClass:"col-12 sm-col-6 md-col-4 px-1 mb-2",attrs:{"label":"Buyer Name","type":"text"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterData($event)}},model:{value:(_vm.filter['appUser:name[partial]']),callback:function ($$v) {_vm.$set(_vm.filter, 'appUser:name[partial]', $$v)},expression:"filter['appUser:name[partial]']"}})],1)]),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.mortgageOtherTableColumns,"rows":_vm.mortgageOtherTableData,"totalRows":_vm.mortgageOtherTablePagination.total,"pagination-options":{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: _vm.perPageOptions,
        dropdownAllowAll: false
      }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.toLeadDetail},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',{on:{"click":function($event){$event.stopPropagation();}}}):(
            props.column.field == 'assignedAgent' &&
            !_vm._.isEmpty(
              props.row.projectPurchaseMortgage.projectPurchase.agent
            )
          )?_c('span',[_c('p',[_vm._v(" "+_vm._s(props.row.projectPurchaseMortgage.projectPurchase.agent.name)+" ")]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-phone mr-1"}),_vm._v(" "+_vm._s(_vm.$getUserPhoneNumber( props.row.projectPurchaseMortgage.projectPurchase.agent.user ))+" ")]),_c('span',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-envelope mr-1"}),_vm._v(_vm._s(props.row.projectPurchaseMortgage.projectPurchase.agent.user .email)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('no-data',{attrs:{"message":"There is no mortgage using \"Other\" method at the moment."}})],1),_c('div',{staticClass:"p-1",attrs:{"slot":"table-actions"},slot:"table-actions"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }