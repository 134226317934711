<template>
  <div class="project-sales-mortgage-other">
    <div class="container fluid">
      <div class="row justify-content-between mx-2 my-4">
        <h2 class="mb-1">Other Mortgage Methods</h2>
        <mortgage-method-dropdown
          :bankLoanRoute="getBankLoanRoute()"
          :bankLoanHistoryRoute="getBankLoanHistoryRoute()"
          :otherRoute="getBankLoanOtherRoute()"
        ></mortgage-method-dropdown>
      </div>

      <h4 class="p-2">Mortgage Buyer</h4>
      <mortgage-other-table
        v-if="!_.isEmpty(API)"
        :detailRouteName="getDetailRouteName()"
        :getMortgageOthers="API.getOtherMethodMortgages"
      ></mortgage-other-table>
    </div>
  </div>
</template>

<script>
import MortgageOtherTable from "@/components/GlobalComponents/Shared/Banker/MortgageOtherTable";
import { projectMortgage as mortgageAPI } from "@/api";
import { projectLeadStatus as LEAD_STATUS } from "@/enums";
import projectMortgageMixin from "@/mixins/project/projectMortgageMixin";

export default {
  components: {
    MortgageOtherTable,
    MortgageMethodDropdown: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/MortgageMethodDropdown"
      )
  },
  mixins: [projectMortgageMixin],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return { LEAD_STATUS, mortgageAPI, API: {} };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = mortgageAPI.getProjectMortgageAPI(this.isAssigned);
    },
    getDetailRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectMortgageOtherDetail"
        : "ManageProjectMortgageOtherDetail";
    }
  }
};
</script>

<style lang="scss">
</style>
